import React from 'react'

interface FooterAddressProps {
  address: {
    name: string
    line1: string
    line2: string
    line3: string
  }
}

const FooterAddress = ({ address }: FooterAddressProps): JSX.Element => {
  return (
    <div className="footer__address" key={address.name}>
      <h4 className="footer__address--company">{address.name}</h4>
      <p className="footer__address--details p">{address.line1}</p>
      <p className="footer__address--details p">{address.line2}</p>
      <p className="footer__address--details p">{address.line3}</p>
    </div>
  )
}

export default FooterAddress
