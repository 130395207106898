import { getMousePos, lerp } from './utils/utils'

import { CursorRenderedStyles } from '../types/types'
import { gsap } from 'gsap'

// Track the mouse position
let cursor: Cursor
let mouse = { x: 0, y: 0 }

if (typeof window !== 'undefined') {
  window.addEventListener('mousemove', (ev) => (mouse = getMousePos(ev)))
}
export class Cursor {
  onMouseMoveEv: () => void
  DOM: { el: HTMLElement }
  static = false
  renderedStyles: CursorRenderedStyles = {
    tx: { previous: 0, current: 0, amt: 0.2 },
    ty: { previous: 0, current: 0, amt: 0.2 },
    scale: { previous: 1, current: 1, amt: 0.2 },
    opacity: { previous: 0, current: 0, amt: 0.2 },
  }
  constructor(el: HTMLElement) {
    this.DOM = { el: el }
    if (el) {
      this.DOM.el.style.opacity = '0'
    }

    this.onMouseMoveEv = () => {
      if (this.static) {
        this.renderedStyles.tx.previous = this.renderedStyles.tx.current = window.innerWidth / 2
        this.renderedStyles.ty.previous = this.renderedStyles.ty.current = window.innerHeight / 2
      } else {
        this.renderedStyles.tx.previous = this.renderedStyles.tx.current = mouse.x
        this.renderedStyles.ty.previous = this.renderedStyles.ty.current = mouse.y
      }
      gsap.to(this.DOM.el, { duration: 0.9, ease: 'Power3.easeOut', opacity: 1 })
      requestAnimationFrame(() => this.render())
      window.removeEventListener('mousemove', this.onMouseMoveEv)
    }
    window.addEventListener('mousemove', this.onMouseMoveEv)
  }
  enter: (size: string) => void = (size) => {
    if (size == 'large') {
      this.renderedStyles['scale'].current = 4
      this.renderedStyles['opacity'].current = 1
    } else {
      this.renderedStyles['scale'].current = 3
      this.renderedStyles['opacity'].current = 1
    }
  }
  leave: () => void = () => {
    this.renderedStyles['scale'].current = 1
    this.renderedStyles['opacity'].current = 0
    this.static = false
  }
  render: () => void = () => {
    if (this.static) {
      this.renderedStyles['tx'].current = window.innerWidth / 2
      this.renderedStyles['ty'].current = window.innerHeight / 2
    } else {
      this.renderedStyles['tx'].current = mouse.x
      this.renderedStyles['ty'].current = mouse.y
    }

    for (const key in this.renderedStyles) {
      if (key === 'tx' || key === 'ty' || key === 'scale' || key === 'opacity') {
        if (this.renderedStyles[key] !== undefined) {
          this.renderedStyles[key].previous = lerp(
            this.renderedStyles[key].previous,
            this.renderedStyles[key].current,
            this.renderedStyles[key].amt
          )
        }
      }
    }
    this.DOM.el.style.transform = `translate3d(${this.renderedStyles['tx'].previous}px, ${this.renderedStyles['ty'].previous}px, 0) scale(${this.renderedStyles['scale'].previous})`
    if (this.renderedStyles['opacity']) {
      this.DOM.el.style.opacity = this.renderedStyles['opacity'].previous.toFixed()
    }

    requestAnimationFrame(() => this.render())
  }
}

const CustomCursor: () => Cursor = () => {
  const currentCursor = document.querySelector('div.cursor')
  if (currentCursor) {
    return currentCursor as unknown as Cursor
  }
  const cursorDiv = document.createElement('div')
  cursorDiv.classList.add('cursor')
  cursorDiv.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="323" viewBox="0 0 323 323"><path d="M161.5 0C72.3 0 0 72.3 0 161.5c0 45.3 18.7 86.2 48.7 115.6 13.9 13.5 30.1 24.6 48.1 32.5 19.8 8.6 41.7 13.4 64.7 13.4 46 0 87.5-19.2 117-50.1 27.6-29 44.5-68.2 44.5-111.4C323 72.3 250.7 0 161.5 0z"/></svg>`
  document.body.appendChild(cursorDiv)
  cursor = new Cursor(cursorDiv)
  return cursor
}

export const getCursor: () => Cursor = () => {
  return cursor
}

export default CustomCursor
