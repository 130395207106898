// Map number x from range [a, b] to [c, d]
const map = (x: number, a: number, b: number, c: number, d: number): number => ((x - a) * (d - c)) / (b - a) + c

// Linear interpolation
const lerp = (a: number, b: number, n: number): number => (1 - n) * a + n * b

interface WinSize {
  width: number
  height: number
}

const calcWinsize: () => WinSize = () => {
  return { width: window.innerWidth, height: window.innerHeight }
}

export interface MousePosition {
  x: number
  y: number
}
// Gets the mouse position
const getMousePos: (e: MouseEvent) => MousePosition = (e) => {
  let posx = 0
  let posy = 0
  if (e.clientX || e.clientY) {
    posx = e.clientX
    posy = e.clientY
  }

  return { x: posx, y: posy }
}

const distance = (x1: number, y1: number, x2: number, y2: number): number => {
  const a = x1 - x2
  const b = y1 - y2

  return Math.hypot(a, b)
}

// Generate a random float.
const getRandomFloat = (min: number, max: number): number => parseFloat((Math.random() * (max - min) + min).toFixed(2))

export { map, lerp, calcWinsize, getMousePos, distance, getRandomFloat }
