import React from 'react'

interface LogoProps {
  className?: string
  color?: string
}

const Logo = ({ className, color }: LogoProps): JSX.Element => {
  return (
    <svg
      width="122"
      height="40"
      viewBox="0 0 122 40"
      xmlns="http://www.w3.org/2000/svg"
      className={`logo ${className}`}
      fill={color}
    >
      <path d="M103.806 12.339C103.046 11.3805 102.017 10.6293 100.714 10.0854C99.4113 9.54145 98.3871 9.27072 96.7412 9.27072C95.4002 9.27072 94.5093 9.49023 93.3207 9.9317C92.1322 10.3732 91.1699 11.0317 90.4363 11.9122C89.7026 12.7927 89.3358 13.8683 89.3358 15.1366V28.7732C89.3358 29.4219 89.5002 29.9073 89.8289 30.2293C90.1576 30.5536 90.6268 30.7146 91.2318 30.7146C91.863 30.7146 92.337 30.5463 92.6538 30.2097C92.9706 29.8732 93.1278 29.3951 93.1278 28.7732V15.1341C93.1278 14.4097 93.5732 13.7317 94.3188 13.3293C95.0643 12.9293 95.6455 12.7268 96.8102 12.7268C97.8226 12.7268 98.2346 12.8463 98.9421 13.0951C99.5613 13.3146 100.171 13.7634 100.805 14.3585C101.336 14.8512 101.929 15.0976 102.589 15.0976C103.017 15.0976 103.372 14.9951 103.651 14.7854C104.08 14.4219 104.297 14.0219 104.297 13.5805C104.299 13.1683 104.135 12.7536 103.806 12.339Z" />
      <path d="M52.9069 28.5829C52.4758 27.6927 44.7679 11.8829 44.1486 10.6293C43.8366 9.99511 43.3221 9.61218 42.6576 9.51706C41.8715 9.40487 41.1903 9.70243 40.6901 10.3732C40.209 11.0195 40.1756 11.761 40.5877 12.5732C40.9569 13.3 48.6171 28.0902 48.6576 28.1536C48.7957 28.3707 48.8648 28.4927 48.7838 28.6756C47.8168 30.8732 45.4468 36.3122 45.3825 36.4585C45.18 36.9195 44.9323 37.3732 44.9466 37.9122C44.937 38.6195 45.1467 39.0415 45.6897 39.4219C45.8541 39.5366 46.0089 39.6341 46.1661 39.7146C46.5115 39.8975 46.8545 39.9902 47.1784 39.9902C47.3594 39.9902 47.5333 39.961 47.7001 39.9049C48.1526 39.7463 48.5218 39.3805 48.7672 38.8439C49.215 37.8658 52.3638 30.639 52.9093 29.4073C53.0379 29.1171 53.0379 28.8536 52.9069 28.5829Z" />
      <path d="M58.6044 20.3561C59.0784 19.2683 61.5389 13.6097 62.3393 11.7732C62.4965 11.4122 62.6418 11.0439 62.8299 10.7C63.2206 9.99023 63.8327 9.66584 64.6235 9.76584C65.3405 9.85609 65.8502 10.2951 66.1408 10.9488C66.2956 11.2951 66.2932 11.7097 66.136 12.0707C65.5834 13.3463 65.0284 14.6219 64.4473 15.8854C64.0995 16.6366 63.4754 17.1561 62.8538 17.6537C61.6175 18.6439 60.2742 19.4707 58.8974 20.239C58.8355 20.2756 58.764 20.2951 58.6044 20.3561Z" />
      <path d="M52.6544 20.3561C53.1284 19.2683 55.5889 13.6097 56.3892 11.7732C56.5465 11.4122 56.6918 11.0439 56.8799 10.7C57.2706 9.99023 57.8827 9.66584 58.6735 9.76584C59.3904 9.85609 59.9002 10.2951 60.1908 10.9488C60.3456 11.2951 60.3432 11.7097 60.186 12.0707C59.6334 13.3463 59.0784 14.6219 58.4972 15.8854C58.1495 16.6366 57.5254 17.1561 56.9037 17.6537C55.6675 18.6439 54.3241 19.4707 52.9474 20.239C52.8878 20.2756 52.8164 20.2951 52.6544 20.3561Z" />
      <path d="M4.05877 30.0146C2.54149 29.4707 1.35292 28.7585 0.493055 27.878C0.164352 27.5415 0 27.139 0 26.6732C0 26.0512 0.252482 25.5463 0.759828 25.1585C1.24097 24.7951 1.68401 24.6146 2.08893 24.6146C2.5939 24.6146 3.07504 24.8488 3.52998 25.3146C4.01113 25.8585 4.73047 26.3317 5.69276 26.7317C6.65266 27.1341 7.67927 27.3341 8.76541 27.3341C10.2065 27.3341 11.326 27.0878 12.1239 26.5951C12.9195 26.1024 13.3196 25.4439 13.3196 24.6146C13.3196 23.8122 12.9266 23.1439 12.143 22.6146C11.3593 22.0829 10.0302 21.6366 8.15803 21.2732C3.30132 20.3146 0.87416 18.2683 0.87416 15.1341C0.87416 13.8658 1.24097 12.7902 1.9746 11.9097C2.70823 11.0293 3.66814 10.3683 4.85909 9.92926C6.04766 9.49023 7.31245 9.26828 8.65347 9.26828C10.297 9.26828 11.7714 9.54145 13.0743 10.0829C14.3772 10.6268 15.4086 11.378 16.166 12.3366C16.4947 12.7512 16.6591 13.1658 16.6591 13.5805C16.6591 14.0219 16.4447 14.4219 16.0136 14.7854C15.7349 14.9927 15.38 15.0975 14.9512 15.0975C14.2938 15.0975 13.6983 14.8512 13.1672 14.3585C12.5336 13.7634 11.8643 13.3415 11.1568 13.0951C10.4494 12.8488 9.58717 12.7268 8.57724 12.7268C7.41249 12.7268 6.45735 12.9268 5.71181 13.3293C4.96627 13.7317 4.59232 14.2951 4.59232 15.0195C4.59232 15.539 4.71856 15.9707 4.97104 16.3219C5.22352 16.6707 5.70467 16.9951 6.41209 17.2927C7.11952 17.5902 8.14374 17.8829 9.48475 18.1658C12.2406 18.7366 14.1962 19.5244 15.3466 20.5366C16.4971 21.5463 17.0735 22.8536 17.0735 24.461C17.0735 25.6512 16.7567 26.7341 16.1255 27.7049C15.4919 28.6756 14.563 29.4415 13.3363 29.9975C12.1096 30.5561 10.6352 30.8317 8.91547 30.8317C7.19574 30.8317 5.57842 30.5585 4.05877 30.0146Z" />
      <path d="M72.7267 14.1634C71.5763 15.2 70.8498 16.5854 70.5449 18.3195H83.1785C82.9499 16.5854 82.3187 15.1976 81.2825 14.1634C80.244 13.1268 78.8911 12.6097 77.2238 12.6097C75.3754 12.6097 73.8772 13.1293 72.7267 14.1634ZM86.175 20.9439C85.8463 21.2683 85.4294 21.4293 84.9221 21.4293H70.5044C70.7831 23.2415 71.5858 24.7049 72.9125 25.8195C74.2416 26.9341 75.8661 27.4902 77.7883 27.4902C78.5481 27.4902 79.3365 27.3488 80.1607 27.0634C80.9824 26.778 81.6589 26.4293 82.1901 26.0146C82.545 25.7293 82.9618 25.5878 83.4429 25.5878C83.9241 25.5878 84.3028 25.7171 84.5815 25.9756C85.0364 26.3634 85.2651 26.8049 85.2651 27.2976C85.2651 27.7634 85.0626 28.1512 84.6577 28.4634C83.7978 29.1634 82.7284 29.7317 81.4517 30.1732C80.175 30.6146 78.953 30.8341 77.7907 30.8341C75.716 30.8341 73.8581 30.3756 72.2122 29.4561C70.5687 28.5366 69.2849 27.261 68.3607 25.6293C67.4365 23.9976 66.9768 22.1463 66.9768 20.0732C66.9768 18.0024 67.4127 16.1439 68.2869 14.4976C69.1586 12.8537 70.3734 11.5707 71.9288 10.6512C73.4842 9.7317 75.2492 9.27316 77.2214 9.27316C79.1698 9.27316 80.8514 9.71463 82.2663 10.5951C83.6835 11.4756 84.7697 12.7073 85.5295 14.2854C86.2893 15.8658 86.668 17.678 86.668 19.7244C86.6704 20.2122 86.5037 20.6195 86.175 20.9439Z" />
      <path d="M108.939 30.0146C107.422 29.4707 106.233 28.7585 105.373 27.878C105.044 27.5415 104.88 27.139 104.88 26.6732C104.88 26.0512 105.133 25.5463 105.64 25.1585C106.121 24.7951 106.564 24.6146 106.967 24.6146C107.472 24.6146 107.953 24.8488 108.408 25.3146C108.889 25.8585 109.608 26.3317 110.57 26.7317C111.53 27.1341 112.557 27.3341 113.643 27.3341C115.084 27.3341 116.204 27.0878 117.002 26.5951C117.797 26.1024 118.197 25.4439 118.197 24.6146C118.197 23.8122 117.804 23.1439 117.021 22.6146C116.237 22.0829 114.908 21.6366 113.036 21.2732C108.179 20.3146 105.752 18.2683 105.752 15.1341C105.752 13.8658 106.119 12.7902 106.852 11.9097C107.586 11.0293 108.546 10.3683 109.737 9.92926C110.925 9.49023 112.19 9.26828 113.531 9.26828C115.175 9.26828 116.649 9.54145 117.952 10.0829C119.255 10.6268 120.286 11.378 121.044 12.3366C121.372 12.7512 121.537 13.1658 121.537 13.5805C121.537 14.0219 121.322 14.4219 120.891 14.7854C120.613 14.9927 120.258 15.0975 119.829 15.0975C119.172 15.0975 118.576 14.8512 118.045 14.3585C117.411 13.7634 116.742 13.3415 116.035 13.0951C115.327 12.8488 114.465 12.7268 113.455 12.7268C112.293 12.7268 111.337 12.9268 110.589 13.3293C109.844 13.7317 109.47 14.2951 109.47 15.0195C109.47 15.539 109.596 15.9707 109.849 16.3219C110.101 16.6707 110.582 16.9951 111.29 17.2927C111.997 17.5902 113.021 17.8829 114.362 18.1658C117.118 18.7366 119.074 19.5244 120.224 20.5366C121.375 21.5463 121.951 22.8536 121.951 24.461C121.951 25.6512 121.634 26.7341 121.003 27.7049C120.37 28.6756 119.441 29.4415 118.214 29.9975C116.987 30.5561 115.513 30.8317 113.793 30.8317C112.076 30.8317 110.458 30.5585 108.939 30.0146Z" />
      <path d="M39.4515 19.6146C39.4396 17.6122 39.0585 15.8341 38.313 14.2805C37.5532 12.7 36.467 11.4707 35.0498 10.5902C33.6325 9.70974 31.7651 9.26828 29.8167 9.26828C27.8445 9.26828 25.8937 9.72926 24.3383 10.6463C22.783 11.5658 21.5682 12.8488 20.6964 14.4927C19.8246 16.1366 19.3864 17.9951 19.3864 20.0683C19.3864 22.1415 19.8484 23.9927 20.7702 25.6244C21.6944 27.2561 22.9759 28.5317 24.6218 29.4512C26.2653 30.3707 28.2375 30.8293 30.3122 30.8293C30.3122 30.8293 31.6293 30.8927 33.9565 30.061C34.752 29.778 35.419 29.3805 35.9692 28.9658C35.9763 29.5415 36.124 29.978 36.4194 30.2683C36.7219 30.5658 37.1506 30.7146 37.708 30.7146C38.2892 30.7146 38.725 30.561 39.0156 30.2512C39.3062 29.9415 39.4515 29.5024 39.4515 28.9317V19.6146ZM22.8425 21.4293C22.6091 19.7171 22.8806 18.3219 22.8806 18.3219C23.1831 16.5878 23.9858 15.2 25.1363 14.1658C26.2867 13.1293 27.9731 12.6122 29.8191 12.6122C31.4888 12.6122 33.0275 13.1317 34.066 14.1658C35.0998 15.2 35.731 16.5805 35.962 18.3097C35.9954 18.5805 36.105 19.5439 36.105 20.8195V25.0561C36.0359 25.1512 35.9692 25.2366 35.9025 25.3049C35.2451 25.9732 34.4067 26.3439 33.3896 26.8024C32.3725 27.261 31.2554 27.4902 30.3145 27.4902C28.3923 27.4902 26.6559 26.9341 25.3268 25.8195C23.9977 24.7049 23.0902 23.2463 22.8425 21.4293Z" />
    </svg>
  )
}

export default Logo
