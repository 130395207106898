import React from 'react'

const IconTwitter = (): JSX.Element => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.1498 3.6434C16.1612 3.80329 16.1612 3.96321 16.1612 4.12311C16.1612 9 12.4493 14.6193 5.66499 14.6193C3.57488 14.6193 1.63326 14.014 0 12.9632C0.296966 12.9975 0.582471 13.0089 0.890863 13.0089C2.61546 13.0089 4.20305 12.4264 5.47083 11.4328C3.84899 11.3985 2.48985 10.3363 2.02156 8.87439C2.25001 8.90863 2.47842 8.93148 2.71829 8.93148C3.0495 8.93148 3.38074 8.88578 3.6891 8.80587C1.99875 8.4632 0.730936 6.97844 0.730936 5.18529V5.13962C1.22204 5.41373 1.79315 5.58505 2.39844 5.60787C1.40478 4.94541 0.753788 3.81471 0.753788 2.53551C0.753788 1.85025 0.936496 1.22207 1.25631 0.673842C3.07232 2.91243 5.80203 4.37433 8.86293 4.53426C8.80583 4.26015 8.77155 3.97464 8.77155 3.6891C8.77155 1.65608 10.4162 2.57493e-08 12.4607 2.57493e-08C13.5228 2.57493e-08 14.4822 0.445432 15.1561 1.16498C15.9898 1.00509 16.7893 0.696694 17.4975 0.274114C17.2233 1.13073 16.6409 1.85028 15.8756 2.3071C16.618 2.22719 17.3376 2.02156 18 1.73606C17.4975 2.46699 16.8693 3.11799 16.1498 3.6434Z" />
    </svg>
  )
}

export default IconTwitter
