import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import { HeaderChild, HeaderItem } from './Header'
import React, { useEffect, useRef } from 'react'

import { Link } from 'gatsby'

interface OffCanvasMenuProps {
  menuOpen: boolean
  items: HeaderItem[]
  setScrollTarget: (elem: HTMLDivElement | null) => void
}

const OffCanvasMenu: React.FC<OffCanvasMenuProps> = ({ menuOpen, items, setScrollTarget }) => {
  const targetRef = useRef(null)

  useEffect(() => {
    setScrollTarget(targetRef.current)
  }, [])

  return (
    <div className={`off-canvas ${menuOpen ? 'off-canvas--open' : ''}`}>
      <div className="off-canvas__inner" ref={targetRef}>
        <Accordion allowZeroExpanded={true}>
          {items.map((item: HeaderItem, index: number) => (
            <div className="off-canvas__item" key={`header-left--${index}`}>
              {item.children && item.children.length > 0 ? (
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <p className="off-canvas__item--title">{item.pageTitle}</p>
                      <div className="off-canvas__expand-icon" />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemState>
                    {({ expanded }) => (
                      <AccordionItemPanel>
                        <ul className={`off-canvas__submenu ${expanded ? 'open' : 'closed'}`}>
                          {!item.disableLink && (
                            <li className="off-canvas__submenu--item">
                              <Link
                                activeClassName="off-canvas--active"
                                to={`/${item.slug}`}
                                aria-label={item.pageTitle}
                              >
                                {item.pageTitle}
                                <span> Home</span>
                              </Link>
                            </li>
                          )}
                          {item.children &&
                            item.children.map((child: HeaderChild, index: number) => (
                              <li
                                key={`off-canvas__submenu--${index}--${child.slug}`}
                                className="off-canvas__submenu--item"
                              >
                                {child.externalUrl ? (
                                  <a href={child.externalUrl} target="__blank" aria-label={child.pageTitle}>
                                    {child.pageTitle}
                                  </a>
                                ) : (
                                  <Link
                                    to={`/${child.slug}`}
                                    activeClassName="off-canvas--active"
                                    aria-label={child.pageTitle}
                                  >
                                    {child.pageTitle}
                                  </Link>
                                )}
                              </li>
                            ))}
                        </ul>
                      </AccordionItemPanel>
                    )}
                  </AccordionItemState>
                </AccordionItem>
              ) : (
                <>
                  {item.disableLink ? (
                    <p className="off-canvas__item--title">{item.pageTitle}</p>
                  ) : (
                    <Link
                      className="off-canvas__item--title"
                      to={`/${item.slug}`}
                      activeClassName="off-canvas--active"
                      aria-label={item.pageTitle}
                    >
                      {item.pageTitle}
                    </Link>
                  )}
                </>
              )}
            </div>
          ))}
        </Accordion>
      </div>
    </div>
  )
}

export default OffCanvasMenu
