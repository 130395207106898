import React, { useEffect, useRef } from 'react'

import MagneticButton from '../../scripts/magnetic-button'
import { navigate } from 'gatsby'

interface ButtonStandardProps {
  href?: string
  text?: string
  isExternalLink: boolean
  className?: string
}

const ButtonStandard = ({ href, text, isExternalLink = false, className }: ButtonStandardProps): JSX.Element => {
  const setClassName = className ? ' ' + className : ''
  const buttonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null)
  const handleClick: () => void = () => {
    if (href) {
      navigate(href)
    }
  }

  useEffect(() => {
    if (buttonRef.current !== null) {
      new MagneticButton(buttonRef.current)
    }
  }, [buttonRef])

  return (
    <>
      {href &&
        text &&
        (isExternalLink ? (
          <a ref={buttonRef} className={`button-standard${setClassName}`} href={href} target="__blank" rel="noreferrer">
            {text}
          </a>
        ) : (
          <button ref={buttonRef} onClick={handleClick} className={`button-standard${setClassName}`}>
            {text}
          </button>
        ))}
    </>
  )
}

export default ButtonStandard
