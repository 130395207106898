import React, { useEffect, useState, useRef } from 'react'
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { ScrollScene } from 'scrollscene'

import Chevron from '../atoms/Chevron'
import { Link } from 'gatsby'
import Logo from '../atoms/Logo'
import MenuButton from './MenuButton'
import OffCanvasMenu from './OffCanvasMenu'
import SubMenu from './SubMenu'

export interface HeaderChild {
  pageTitle: string
  slug: string
  id: string
  externalUrl: string
}

export interface HeaderItem {
  pageTitle: string
  slug: string
  disableLink: boolean
  displayAsButton: boolean
  children?: HeaderChild[]
}

export interface HeaderType {
  pages: HeaderItem[]
  transparent: boolean
  darkMode: boolean
}

const Header: React.FC<HeaderType> = ({ pages, transparent, darkMode }: HeaderType) => {
  const rightItems = pages.filter((page) => !page.displayAsButton)
  const leftItems = pages.filter((page) => !!page.displayAsButton)
  const headerRef = useRef<HTMLDivElement>(null)
  const [colorMode, setColorMode] = useState(transparent)
  const [menuOpen, setMenuOpen] = useState(false)
  const [targetElement, setTarget] = useState(null)

  useEffect(() => {
    let scene

    if (headerRef.current && headerRef.current.nextElementSibling?.children[1]) {
      const nextElement = headerRef.current?.nextElementSibling?.children[1]

      if (nextElement) {
        scene = new ScrollScene({
          triggerElement: nextElement,
          triggerHook: 0.1,
          toggle: {
            element: headerRef.current,
            className: 'header--scrolled',
            reverse: false,
          },
        })

        scene.Scene.on('enter', () => {
          setColorMode(false)
        }).on('leave', () => {
          setColorMode(transparent)
        })

        return () => {
          scene.destroy()
        }
      }
    }
  })

  const toggleMenu = () => {
    if (menuOpen === false) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    return function cleanup() {
      clearAllBodyScrollLocks()
      setMenuOpen(false)
    }
  }, [])

  return (
    <header
      className={`header${colorMode ? ' header--transparent' : ' header--solid'}${
        darkMode ? ' header--dark-mode' : ''
      }${menuOpen ? ' header--menu-open' : ''}`}
      ref={headerRef}
    >
      <div className="header__links">
        <Link to="/" aria-label="Home">
          <Logo className="header__logo" />
        </Link>
        <nav className="header__left-menu">
          {rightItems.map((item: HeaderItem, index: number) => (
            <div className="header__item" key={`header-left--${index}`}>
              {item.disableLink ? (
                <p className="header__item--title">{item.pageTitle}</p>
              ) : (
                <Link
                  className="header__item--title"
                  to={`/${item.slug}`}
                  activeClassName="active"
                  aria-label={item.pageTitle}
                >
                  {item.pageTitle}
                </Link>
              )}
              {item.children && item.children.length > 0 && (
                <>
                  <Chevron />
                  <SubMenu menuChildren={item.children} />
                </>
              )}
            </div>
          ))}
        </nav>
      </div>
      <div className="header__right-menu">
        {leftItems.map((item: HeaderItem, index: number) => (
          <div className="header__button" key={`header-right--${index}`}>
            {item.disableLink ? (
              <p className="header__button--title">{item.pageTitle}</p>
            ) : (
              <Link to={`/${item.slug}`} className="header__button--title" aria-label={item.pageTitle}>
                {item.pageTitle}
              </Link>
            )}
            {item.children && item.children.length > 0 && <SubMenu menuChildren={item.children} />}
          </div>
        ))}
      </div>
      <MenuButton menuOpen={menuOpen} toggleMenu={toggleMenu} />
      <OffCanvasMenu menuOpen={menuOpen} items={pages} setScrollTarget={(elem) => setTarget(elem)} />
    </header>
  )
}

export default Header
