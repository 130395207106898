import 'fontsource-ibm-plex-sans'
import 'fontsource-roboto-mono'
import '../styles/index.scss'

import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Cursor from '../scripts/cursor'
import Footer from '../components/navigation/Footer'
import Header from '../components/navigation/Header'

interface LayoutProps {
  children: JSX.Element[] | JSX.Element
  transparent: boolean
  darkMode: boolean
}

const Layout = ({ children, transparent, darkMode }: LayoutProps): JSX.Element => {
  const { allDatoCmsPage, datoCmsFooter } = useStaticQuery(graphql`
    query {
      allDatoCmsPage(filter: { root: { eq: true } }, sort: { fields: [position], order: ASC }) {
        edges {
          node {
            id
            slug
            pageTitle
            displayInHeader
            disableLink
            displayAsButton
            treeChildren {
              id
              slug
              pageTitle
              externalUrl
              position
            }
          }
        }
      }
      datoCmsFooter {
        id
        address
        addresses {
          name
          line1
          line2
          line3
        }
        companyName
        emailAddress
        footerLinks {
          id
          slug
          pageTitle
          externalUrl
        }
        legalInformation
        linkedinUrl
        phoneNumber
        tagline
        twitterUrl
        awardUrl
        awardImage {
          fluid {
            aspectRatio
            src
            srcSet
          }
        }
        __typename
      }
    }
  `)

  const headerData = allDatoCmsPage.edges
    .filter((item) => item.node.displayInHeader === true)
    .map((item) => {
      return {
        pageTitle: item.node.pageTitle,
        slug: item.node.slug,
        disableLink: item.node.disableLink,
        displayAsButton: item.node.displayAsButton,
        children: item.node.treeChildren.sort((a, b) => a.position - b.position),
      }
    })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      Cursor()
    }
  }, [])

  return (
    <>
      <Header pages={headerData} transparent={transparent} darkMode={darkMode} />
      <main>{children}</main>
      <Footer data={datoCmsFooter} />
    </>
  )
}

export default Layout
