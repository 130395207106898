import * as React from 'react'

import { FooterType } from '../../types/types'
import IconLinkedIn from '../atoms/Icon-LinkedIn'
import IconTwitter from '../atoms/Icon-Twitter'
import { Link } from 'gatsby'
import Logo from '../atoms/Logo'
import LogoNewQuo from '../atoms/LogoNewQuo'
import FooterAddress from '../atoms/FooterAddress'

import 'swiper/scss'
import 'swiper/scss/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

interface FooterProps {
  data: FooterType
}

const Footer = ({ data }: FooterProps): JSX.Element => {
  const { addresses, emailAddress, footerLinks, legalInformation, linkedinUrl, phoneNumber, tagline, twitterUrl } = data

  return (
    <div className="footer">
      <div className="footer__top">
        <div className="footer__top--col-1">
          <div className="footer__top--logo-wrapper">
            <Logo color="#fff" />
            <LogoNewQuo />
          </div>
          {tagline && (
            <div
              className="footer__top--tagline p"
              dangerouslySetInnerHTML={{ __html: `<div> ${tagline} </div>` }}
            ></div>
          )}
          <div className="footer__top--contact-links">
            {phoneNumber && (
              <p className="footer__top--link">
                <span>T - &nbsp;</span>
                <a href={`tel:${phoneNumber.split(' ').join('')}`} aria-label={`Phone: ${phoneNumber}`}>
                  {phoneNumber}
                </a>
              </p>
            )}
            {emailAddress && (
              <p className="footer__top--link">
                <span>E - &nbsp;</span>
                <a href={`mailto:${emailAddress}`} aria-label={`Email: ${emailAddress}`}>
                  {emailAddress}
                </a>
              </p>
            )}
            <div className="footer__top--social">
              {linkedinUrl && (
                <a target="__blank" href={linkedinUrl} aria-label="LinkedIn">
                  <IconLinkedIn />
                </a>
              )}
              {twitterUrl && (
                <a target="__blank" href={twitterUrl} aria-label="Twitter">
                  <IconTwitter />
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="footer__top--col-2">
          <Swiper spaceBetween={24} slidesPerView="auto" observer={true} observeParents={true} pagination>
            {addresses &&
              addresses.map((address, index) => (
                <SwiperSlide key={index}>
                  <FooterAddress address={address} />
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="footer__addresses-desktop">
            {addresses && addresses.map((address, index) => <FooterAddress address={address} key={index} />)}
          </div>
        </div>
      </div>
      {legalInformation && (
        <div className="footer__legal">
          <div
            className="p footer__legal--text"
            dangerouslySetInnerHTML={{ __html: `<div> ${legalInformation} </div>` }}
          />
        </div>
      )}
      <div className="footer__links">
        <ul>
          {footerLinks &&
            footerLinks.map((link) => {
              return (
                <li key={link.id} className="footer__links--page">
                  {link.externalUrl ? (
                    <a href={link.externalUrl} target="_blank" rel="noreferrer">
                      {link.pageTitle}
                    </a>
                  ) : (
                    <Link to={`/${link.slug}`}>{link.pageTitle}</Link>
                  )}
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default Footer
