import React from 'react'

const IconLinkedIn = (): JSX.Element => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF">
      <path d="M4.02901 17.996H0.297255V5.98125H4.02901V17.996ZM2.16112 4.34233C0.968087 4.34233 0 3.35416 0 2.16112C0 0.968088 0.968087 0 2.16112 0C3.35416 0 4.32225 0.968088 4.32225 2.16112C4.32225 3.35416 3.35416 4.34233 2.16112 4.34233ZM17.996 17.996H14.2723V12.1473C14.2723 10.7534 14.2441 8.96586 12.3321 8.96586C10.3919 8.96586 10.0946 10.4802 10.0946 12.0469V17.996H6.36688V5.98125H9.94599V7.62017H9.99821C10.4963 6.67619 11.7135 5.67998 13.5291 5.67998C17.3051 5.67998 18 8.16648 18 11.3961V17.996H17.996Z" />
    </svg>
  )
}

export default IconLinkedIn
