import * as React from 'react'

import { HeaderChild } from './Header'
import { Link } from 'gatsby'

interface SubMenuProps {
  menuChildren: HeaderChild[]
}

const SubMenu: React.FC<SubMenuProps> = ({ menuChildren }: SubMenuProps) => (
  <ul className="header__submenu">
    {menuChildren.map((child: HeaderChild, index: number) => (
      <li className="header__submenu--item" key={`${child.slug}--submenu--${index}`}>
        {child.externalUrl ? (
          <a href={child.externalUrl} target="__blank" aria-label={child.pageTitle}>
            {child.pageTitle}
          </a>
        ) : (
          <Link to={`/${child.slug}`} activeClassName="active" aria-label={child.pageTitle}>
            {child.pageTitle}
          </Link>
        )}
      </li>
    ))}
  </ul>
)

export default SubMenu
