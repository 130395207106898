import React from 'react'

const LogoNewQuo = (): JSX.Element => {
  return (
    <svg width="148" height="24" viewBox="0 0 148 24" className="logo__new-quo" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.02638 0C11.5649 0 14.4294 2.70458 14.4294 6.42232V16.5645C14.4294 16.9373 14.127 17.2407 13.7552 17.2407H12.0708C11.6993 17.2407 11.3969 16.9373 11.3969 16.5645V6.42232C11.3969 4.46129 9.77989 3.0416 7.69018 3.0416C5.60074 3.0416 3.98373 4.29277 3.98373 6.2538V16.5645C3.98373 16.9373 3.68135 17.2407 3.30952 17.2407H1.62544C1.25362 17.2407 0.951233 16.9373 0.951233 16.5645V1.01526C0.951233 0.642445 1.25362 0.339121 1.62544 0.339121H3.10803C3.30953 0.339121 3.44395 0.440229 3.58046 0.575031C3.78223 0.810968 4.08671 1.11637 4.42263 1.11637C5.39713 1.11427 5.66809 0 8.02638 0Z"
        fill="#3D33FF"
      />
      <path
        d="M26.1912 14.5361C27.9448 14.5361 29.2573 13.6577 29.6626 12.4065C29.7971 11.9663 30.1351 11.8315 30.4375 11.8315H32.1553C32.4915 11.8315 32.796 12.0337 32.7287 12.5076C32.3233 15.4481 29.6962 17.5777 26.1891 17.5777C22.1447 17.5777 19.549 14.7046 19.2801 11.1553C19.1792 9.80305 19.1792 7.77463 19.2801 6.42232C19.549 2.87311 22.1447 0 26.1891 0C30.2674 0 32.9304 2.53606 32.9304 6.76144V9.09321C32.9304 9.46603 32.6278 9.76935 32.2562 9.76935H22.6171C22.4153 9.76935 22.2809 9.90415 22.2809 10.1064C22.2809 13.1838 23.8665 14.5361 26.1912 14.5361ZM22.6863 7.06477H29.6962C29.8979 7.06477 30.0324 6.92997 30.0324 6.72565C30.0324 4.39391 28.3145 3.0416 26.225 3.0416C23.9003 3.0416 22.3483 4.66351 22.3483 6.72565C22.3504 6.92997 22.4848 7.06477 22.6863 7.06477Z"
        fill="#3D33FF"
      />
      <path
        d="M45.1988 4.0548C45.5011 4.0548 45.7385 4.18963 45.8394 4.56245L47.5235 10.9174C47.5908 11.187 47.9289 11.187 47.9959 10.9174L49.5123 0.842604C49.5794 0.469763 49.8482 0.334961 50.1527 0.334961H51.8054C52.3451 0.334961 52.5131 0.77519 52.446 1.11221L49.5815 16.731C49.5144 17.068 49.2435 17.2386 48.9411 17.2386H47.0867C46.7843 17.2386 46.5469 17.0701 46.4463 16.731L44.7955 10.138C44.7284 9.9021 44.4596 9.9021 44.3904 10.138L42.7396 16.731C42.639 17.068 42.4015 17.2386 42.0992 17.2386H40.2448C39.9424 17.2386 39.6714 17.0701 39.6044 16.731L36.7398 1.11221C36.6727 0.77519 36.8407 0.334961 37.3805 0.334961H39.031C39.3334 0.334961 39.6043 0.469763 39.6714 0.842604L41.1878 10.9174C41.2214 11.187 41.5929 11.187 41.6603 10.9174L43.3443 4.56245C43.4452 4.18963 43.6805 4.0548 43.985 4.0548H45.1988Z"
        fill="#3D33FF"
      />
      <path
        d="M99.8634 17.5777C96.0561 17.5777 93.4604 14.8731 93.4604 11.1554V1.01318C93.4604 0.64036 93.7628 0.337036 94.1346 0.337036H95.8187C96.1905 0.337036 96.4929 0.64036 96.4929 1.01318V11.1554C96.4929 13.1164 97.8746 14.5361 100.199 14.5361C102.289 14.5361 103.906 13.2849 103.906 11.3239V1.01318C103.906 0.64036 104.208 0.337036 104.58 0.337036H106.264C106.636 0.337036 106.939 0.64036 106.939 1.01318V16.5624C106.939 16.9352 106.634 17.2386 106.264 17.2386H104.782C104.58 17.2386 104.443 17.1375 104.309 17.0027C104.108 16.7667 103.803 16.4613 103.467 16.4613C102.493 16.4613 102.358 17.5777 99.8634 17.5777Z"
        fill="#3D33FF"
      />
      <path
        d="M125.644 6.42232C125.744 7.77463 125.744 9.80305 125.644 11.1553C125.375 14.7046 122.78 17.5777 118.735 17.5777C114.692 17.5777 112.095 14.7046 111.825 11.1553C111.725 9.80305 111.725 7.77463 111.825 6.42232C112.097 2.87311 114.692 0 118.735 0C122.78 0 125.375 2.87311 125.644 6.42232ZM122.613 11.1553C122.712 9.76935 122.712 7.80832 122.613 6.42232C122.477 4.62982 121.062 3.0416 118.735 3.0416C116.41 3.0416 114.996 4.62982 114.859 6.42232C114.757 7.80832 114.757 9.76935 114.859 11.1553C114.993 12.9479 116.408 14.5361 118.735 14.5361C121.062 14.5361 122.477 12.9458 122.613 11.1553Z"
        fill="#3D33FF"
      />
      <path
        d="M91.9118 21.1943H88.3731C88.1716 21.1943 88.035 21.0595 88.035 20.8573V13.4913V1.01316C88.035 0.640343 87.7306 0.337019 87.3608 0.337019H86.2144C86.0127 0.337019 85.8782 0.438126 85.7417 0.572928C85.5402 0.808838 85.2357 1.11427 84.8998 1.11427C83.9232 1.11427 83.6187 0 81.126 0C77.5873 0 74.9918 2.87311 74.723 6.42232C74.6221 7.77463 74.6221 9.80305 74.723 11.1553C74.9939 14.7046 77.5873 17.5777 81.126 17.5777C82.6757 17.5777 83.5852 16.733 84.2591 16.733C84.7654 16.733 85.0004 16.9352 85.0004 17.5777V23.3237C85.0004 23.6967 85.3028 24 85.6747 24H87.3587H91.9097C92.2813 24 92.5837 23.6946 92.5837 23.3237V21.8706C92.5858 21.4997 92.2813 21.1943 91.9118 21.1943ZM81.462 14.5361C79.1372 14.5361 77.7196 12.9479 77.5852 11.1553C77.4846 9.76935 77.4846 7.80832 77.5852 6.42232C77.7196 4.62982 79.1351 3.0416 81.462 3.0416C83.7867 3.0416 85.2022 4.62982 85.3387 6.42232C85.4393 7.80832 85.4393 9.76935 85.3387 11.1553C85.2043 12.9458 83.7867 14.5361 81.462 14.5361Z"
        fill="#3D33FF"
      />
      <path d="M147.951 21.1943H130.531V24H147.951V21.1943Z" fill="#3D33FF" className="logo__new-quo--dash" />
    </svg>
  )
}

export default LogoNewQuo
