import { Cursor, getCursor } from './cursor'
import { MousePosition, distance, getMousePos, lerp } from './utils/utils'

import { EventEmitter } from 'events'
import { MagneticButtonRenderedStyles } from '../types/types'

// Track the mouse position
let mousepos: MousePosition = { x: 0, y: 0 }
if (typeof window !== 'undefined') {
  window.addEventListener('mousemove', (ev) => (mousepos = getMousePos(ev)))
}
export default class ButtonCtrl extends EventEmitter {
  DOM: { el: HTMLElement; text?: HTMLElement }
  renderedStyles: MagneticButtonRenderedStyles = {
    tx: { previous: 0, current: 0, amt: 0.01 },
    ty: { previous: 0, current: 0, amt: 0.01 },
  }
  hover = false
  rect: DOMRect = new DOMRect()
  distanceToTrigger = 0
  cursor: Cursor
  onResize?: () => void
  constructor(el: HTMLElement) {
    super()
    this.DOM = { el: el }
    el.classList.add('magnetic-button')
    this.DOM.text = this.DOM.el.querySelector('.magnetic-button__text') as HTMLElement
    // calculate size/position
    this.calculateSizePosition()
    // init events
    this.initEvents()
    // loop fn
    this.cursor = getCursor()

    requestAnimationFrame(() => this.render())
  }
  calculateSizePosition: () => void = () => {
    // size/position
    this.rect = this.DOM.el.getBoundingClientRect()

    // the movement will take place when the distance from the mouse to the center of the button is lower than this value
    this.distanceToTrigger = (this.rect.width / 2) * 1
  }
  initEvents: () => void = () => {
    this.onResize = () => this.calculateSizePosition()
    this.on('enter', () => {
      if (!this.cursor) {
        this.cursor = getCursor()
      }
      this.cursor.enter('')
    })
    this.on('leave', () => {
      if (!this.cursor) {
        this.cursor = getCursor()
      }
      this.cursor.leave()
    })
    window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.onResize)
  }
  render: () => void = () => {
    // calculate the distance from the mouse to the center of the button
    const distanceMouseButton = distance(
      mousepos.x,
      mousepos.y,
      this.rect.left + this.rect.width / 2,
      this.rect.top + this.rect.height / 2
    )
    // new values for the translations
    let x = 0
    let y = 0

    if (distanceMouseButton < this.distanceToTrigger) {
      if (!this.hover) {
        this.enter()
      }
      x = (mousepos.x - (this.rect.left + this.rect.width / 2)) * 0.3
      y = (mousepos.y - (this.rect.top + this.rect.height / 2)) * 0.3
    } else if (this.hover) {
      this.leave()
    }

    this.renderedStyles['tx'].current = x
    this.renderedStyles['ty'].current = y

    this.renderedStyles['tx'].previous = lerp(
      this.renderedStyles['tx'].previous,
      this.renderedStyles['tx'].current,
      this.renderedStyles['tx'].amt
    )
    this.renderedStyles['ty'].previous = lerp(
      this.renderedStyles['ty'].previous,
      this.renderedStyles['ty'].current,
      this.renderedStyles['ty'].amt
    )

    if (this.hover) {
      this.DOM.el.style.transform = `translate3d(${this.renderedStyles['tx'].previous}px, ${this.renderedStyles['ty'].previous}px, 0)`
      if (this.DOM.text) {
        this.DOM.text.style.transform = `translate3d(${this.renderedStyles['tx'].previous * 0.1}px, ${
          this.renderedStyles['ty'].previous * 0.1
        }px, 0)`
      }
    }

    requestAnimationFrame(() => this.render())
  }
  enter: () => void = () => {
    this.emit('enter')
    this.hover = true
    this.DOM.el.classList.add('magnetic-button--hover')
    document.body.classList.add('active')
  }
  leave: () => void = () => {
    this.emit('leave')
    this.hover = false
    this.DOM.el.classList.remove('magnetic-button--hover')
    document.body.classList.remove('active')
  }
}
