import * as React from 'react'

interface MenuButtonProps {
  menuOpen: boolean
  toggleMenu: () => void
}

const MenuButton: React.FC<MenuButtonProps> = ({ menuOpen, toggleMenu }: MenuButtonProps) => {
  return (
    <div className={`menu-button ${menuOpen ? 'opening-menu' : ''}`} onClick={toggleMenu}>
      <span className="menu-button__top" />
      <span className="menu-button__mid" />
      <span className="menu-button__bottom" />
    </div>
  )
}

export default MenuButton
