import React from 'react'

interface ChevronProps {
  className?: string
}

const Chevron = ({ className }: ChevronProps): JSX.Element => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`chevron ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.325409 6.85267L1.44322 5.73486L7.2076 11.4992L12.972 5.73486L14.0898 6.85267L7.2076 13.7349L0.325409 6.85267Z"
        fill="#9D99FF"
      />
    </svg>
  )
}

export default Chevron
